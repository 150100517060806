import React from "react"
import { graphql } from 'gatsby'
import styled from "@emotion/styled";

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import Container from '../../components/common/Container'
import Offers from '../../components/common/Offers'
import {Box, Flex} from "../../components/common/Grid";
import Button from "../../components/common/Button";
import sendEvent from "../../helpers/sendAnalytics";
import EventTracker from "../../components/common/EventTracker";
import Image from '../../components/common/Image'
import Packs from "../../assets/new-clusters/Web_ClusterPacks_WhereToBuy_EN.png";
import Walmart from "../../assets/images/Walmart_logo.png";
import RealCanadian from "../../assets/images/Real-Canadian-Super-Store.webp"
import NoFrills from "../../assets/images/No-Frills.webp";
import Loblaws from "../../assets/images/Loblaws2.webp";
import Sobeys from "../../assets/images/Sobeys-Voila.webp";
import Metro from "../../assets/images/Metro.png";
import HomeHardware from "../../assets/images/Home-Hardware.webp";
import Amazon from "../../assets/images/Amazon.webp";
import Shoppers from "../../assets/images/Shoppers.png";
import Maxi from "../../assets/images/Maxi.png";
import Iga from "../../assets/images/IGA.png";
// import Pharmaprix from "../../assets/images/Pharmaprix.png";




const PageHeader = styled.section`
  ${tw`bg-light-blue py-12`}

  margin-bottom: 100px;

  img {
    width: 100%;
  }

  ${Box} ${Box} {
    width: 100%;
  }

  @media screen and (max-width: 40em) {
    ${tw`py-10 pb-20`}
    margin-bottom: 50px;
  }
`

const H1 = styled.h1`
  ${tw`font-montserrat text-4xl font-light text-black leading-tight m-0`}
  max-width: 350px;

  @media screen and (max-width: 40em) {
    ${tw`mb-6`}
  }
`

const Subtitle = styled.div`
  ${tw`font-raleway font-medium text-lg leading-loose text-blog-text`}

  max-width: 500px;
  margin-left: auto;

  p {
    margin: 0px;
  }

  @media screen and (min-width: 40em) {
    ${tw`m-0 my-5`}
  }
`

const BuyOnlineWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 100px 100px;

  @media screen and (max-width: 920px) {
    display: block;
  }
`

const BuyOnlineWrapperQuebec = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 100px 100px;

  @media screen and (max-width: 920px) {
    display: block;
  }
`

const BuyOnlineBoxStyled = styled.div`
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;

  img {
    margin-bottom: 40px;
    max-height: 150px;
  }

  @media screen and (max-width: 920px) {
    margin-bottom: 50px;
  }
`

const StyledH2 = styled.h2`
  font-size: 24px;
  margin: 10px 0;
  font-weight: 400;
  padding: 25px 0;
`;


const QuebecTitle = ({ title }) => (
  <StyledH2>{title}</StyledH2>
)

const BuyOnlineBox = ({ logo, title, link, onClick }) => (
  <BuyOnlineBoxStyled>
    <Image
      url={logo}
      alt={`Buy Online - ${title}`}
      sizes="250px"
    />
    {link && (
      <Button to={link} title={`Buy Online - ${title}`} label={`Buy Online - ${title}`} onClick={onClick}>
        Buy Online
      </Button>
    )}
  </BuyOnlineBoxStyled>
)

const BuyOnlinePage = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='en'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='en'
    />

    <PageHeader>
      <Container>
        <Flex
          alignItems="center"
          flexWrap='wrap'
        >
          <Box width={[
            1,
            1,
            1,
            1/2
          ]}
          >
            <Flex
              alignItems={[
                "flex-start",
                "flex-start",
                "flex-start",
                "center"
              ]}
              flexDirection={[
                'column',
                'column',
                'column',
                'row'
              ]}
            >
              <Box>
                {/* <Image */}
                {/*   url={data.defaultPage.image_url} */}
                {/*   alt="Royale" */}
                {/*   sizes="(min-width: 64em) 600px, 100vw" */}
                {/* /> */}
                <img src={Packs} alt="Royale product pack" />
              </Box>
            </Flex>
          </Box>

          <Box
            width={[
              1,
              1,
              1,
              1/2
            ]}
            pl={[
             0,0,0,5
           ]}
          >
            <H1 dangerouslySetInnerHTML={{ __html: data.defaultPage.title_html }} />
            <Subtitle dangerouslySetInnerHTML={{ __html: data.defaultPage.subtitle }} />
          </Box>
        </Flex>
      </Container>
    </PageHeader>

    <main>
      <Container>
        <BuyOnlineWrapper>
          <BuyOnlineBox logo={Walmart} title="Walmart" link="https://www.walmart.ca/en/browse/household-supplies/paper-products/6000195358079_32361?icid=dept_flyout_household_supplies_and_pantry_shop_all_paper_products_59656_8FEXQS17VU&facet=brand%3ARoyale" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:v2pwepq&fmt=3',gtag: {send_to: 'AW-951001886/PwXoCPvMmf0YEJ7GvMUD'}})} />
          <BuyOnlineBox logo={RealCanadian} title="Real Canadian Superstore" link="https://www.realcanadiansuperstore.ca/home-and-living/household-cleaning-products/paper-products-/c/28175?navid=flyout-L3-Bathroom-Tissues-Paper-Products&productBrand=RYLE " onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:oivj0j7&fmt=3',gtag: {send_to: 'AW-951001886/GXlvCP29m_0YEJ7GvMUD'}})} />
          <BuyOnlineBox logo={NoFrills} title="No Frills" link="https://www.nofrills.ca/home-and-living/household-cleaning-products/paper-products-/c/28175?navid=flyout-L3-Bathroom-Tissues-Paper-Products&productBrand=RYLE" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:blsopgp&fmt=3',gtag: {send_to: 'AW-951001886/J6ZqCKLWmf0YEJ7GvMUD'}})} />
          <BuyOnlineBox logo={Loblaws} title="Loblaws" link="https://www.loblaws.ca/home-and-living/household-cleaning-products/paper-products-/c/28175?navid=flyout-L3-Bathroom-Tissues-Paper-Products&productBrand=RYLE" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:vzgpbqe&fmt=3',gtag: {send_to: 'AW-951001886/jzoQCM6Pl_0YEJ7GvMUD'}})} />

          <BuyOnlineBox logo={Shoppers} title="Shoppers" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:vzgpbqe&fmt=3',gtag: {send_to: 'AW-951001886/jzoQCM6Pl_0YEJ7GvMUD'}})} />

          <BuyOnlineBox logo={Sobeys} title="Sobeys - Voila" link="https://voila.ca/products/search?brands=Royale&q=Royale&sortBy=favorite" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:81psgmn&fmt=3',gtag: {send_to: 'AW-951001886/2gNuCK3Hm_0YEJ7GvMUD'}})} />
          <BuyOnlineBox logo={Metro} title="Metro" link="https://www.metro.ca/en/online-grocery/aisles/household-cleaning/paper?sortOrder=relevance&filter=:relevance:brand:Royale" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:ceek0hg&fmt=3',gtag: {send_to: 'AW-951001886/_m-mCO2Tl_0YEJ7GvMUD'}})} />
          <BuyOnlineBox logo={HomeHardware} title="Home Hardware" link="https://www.homehardware.ca/en/brands/royale" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:xamfieb&fmt=3',gtag: {send_to: 'AW-951001886/89twCMbfmf0YEJ7GvMUD'}})} />
          <BuyOnlineBox logo={Amazon} title="Amazon Canada" link="https://www.amazon.ca/stores/ROYALE%C2%AE/page/426D735B-35E4-4322-B2FE-726F11F1CDA4?ref_=ast_bln" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:si6jr5d&fmt=3',gtag: {send_to: 'AW-951001886/d_28CPSal_0YEJ7GvMUD'}})} />
        </BuyOnlineWrapper>
        <QuebecTitle title="In Quebec Only" />

        <BuyOnlineWrapperQuebec>
          <BuyOnlineBox logo={Maxi} title="Maxi" link="https://www.maxi.ca/search?search-bar=royale" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:v2pwepq&fmt=3',gtag: {send_to: 'AW-951001886/PwXoCPvMmf0YEJ7GvMUD'}})} />
          <BuyOnlineBox logo={Iga} title="Iga" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:oivj0j7&fmt=3',gtag: {send_to: 'AW-951001886/GXlvCP29m_0YEJ7GvMUD'}})} />
          {/* 
            <BuyOnlineBox logo={Pharmaprix} title="Pharmaprix" onClick={() => sendEvent({appnexus: 'https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:blsopgp&fmt=3',gtag: {send_to: 'AW-951001886/J6ZqCKLWmf0YEJ7GvMUD'}})} />
          */}
        </BuyOnlineWrapperQuebec>

      </Container>
    </main>

    <EventTracker appnexus='https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:d387cwd&fmt=3' />

    <Footer
      extraRules='Each year, forests managed by J.D Irving, Limited and affiliated corporations remove more carbon than is emitted in the lifecycle of Royale<sup>®</sup> tissue products.'
    />
    <Offers />
  </Layout>
)

export const query = graphql`
query {
  defaultPage(guid: { eq: "ee65cb8e-5cac-4013-bc5c-31d075d0ab89" }, locale: {eq:"en"}) {
    title_html
    subtitle
    description
    image_url
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default BuyOnlinePage
